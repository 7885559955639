<template>
    <header class="p-3 bg-black flex items-center justify-between border-b border-gray-500 text-lg z-3">
        <!-- Left Side -->
        <div class="flex items-center">
            <!-- Company Logo -->
            <img src="@/assets/images/cg.png" alt="Company Logo" class="h-8 w-8 mr-2 ml-1">

            <div class="inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </div>

            <div class="inline-flex items-center cursor-pointer">
                <!-- <p class="text-white">Reef Intelligence</p> -->
                <div class="inline-flex items-center space-x-2 font-sans">
                    <span class="text-white text-base">Salomé</span>
                    <span class="bg-white text-black text-xs font-bold px-1.5 py-0.5 rounded">AI</span>
                </div>
            </div>
        </div>
        <!-- Right Side -->
        <div class="flex items-center text-base">
            <div class="relative">
                <button @click="toggleDropdown" class="text-white focus:outline-none">
                    <span class="mdi mdi-dots-vertical h-6 w-6 text-2xl ml-2"></span>
                </button>
                <div v-if="dropdownOpen"
                    class="absolute top-full right-0 mt-2 w-36 bg-black border border-neutral-700 rounded shadow-lg z-20">
                    <!-- Dropdown items -->
                    <div class="py-1">
                        <a href="#" class="block px-4 py-2 text-sm text-white" @click="logout">
                            <span class="mdi mdi-logout mr-2"></span>Logout
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue';
import { db } from '@/main.js'
import { collection, getDocs } from "firebase/firestore";
import { EventBus } from '@/event-bus';

export default {
    name: 'Header',
    components: {
        Dropdown
    },
    data() {
        return {
            dropdownOpen: false,
        }
    },
    mounted() {
        
    },
    methods: {
        logout() {
            this.$emit('logout');
            this.toggleDropdown();
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
    },
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>