<template>
    <transition name="fade">
        <div v-if="visible" :class="['toast', type]">
            <div v-if="loading" class="ringing-phone">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path
                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                    </path>
                </svg>
            </div>
            <span>{{ message }}</span>
        </div>
    </transition>
</template>

<script>
import { EventBus } from '@/event-bus.js';

export default {
    data() {
        return {
            visible: false,
            message: '',
            type: '',
            loading: false,
            timeoutId: null,
        };
    },
    created() {
        EventBus.$on('show-toast', this.showToast);
        EventBus.$on('hide-toast', this.hideToast);
    },
    beforeDestroy() {
        EventBus.$off('show-toast', this.showToast);
        EventBus.$off('hide-toast', this.hideToast);
        this.clearTimeout();
    },
    methods: {
        showToast({ message, type, duration = 2000, loading = false }) {
            this.clearTimeout();
            this.message = message;
            this.type = type;
            this.loading = loading;
            this.visible = true;

            if (!loading) {
                this.timeoutId = setTimeout(() => {
                    this.hideToast();
                }, duration);
            }
        },
        hideToast() {
            this.visible = false;
            this.loading = false;
            this.clearTimeout();
        },
        clearTimeout() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
        }
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.toast {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    color: #fff;
    border-radius: 25px;
    z-index: 1000;
    background-color: #27bdf4;
}

.success {
    background-color: #3AB749;
}

.error {
    background-color: #FF3A3A;
}

.ringing-phone {
    animation: ring 0.8s ease-in-out infinite;
}

@keyframes ring {

    0%,
    100% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-10deg);
    }

    75% {
        transform: rotate(10deg);
    }
}
</style>