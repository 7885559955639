<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html {
  -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}
html::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}
</style>
