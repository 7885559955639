<template>
    <div class="main-container">
        <ToastNotification />
        <Header class="h-12" @logout="handleLogout" />
        <div class="main-content">
            <LeftSidebar v-if="!isMobile" />
            <div class="content-wrapper">
                <div class="content" v-if="data">
                    <div class="messages" ref="messagesContainer">
                        <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.type]">
                            <div v-if="msg.type === 'system'" v-html="msg.text"></div>
                            <div v-else>{{ msg.text }}</div>
                        </div>
                        <div v-if="isLoading" class="message system loading">
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                        </div>
                    </div>
                    <div class="input-container">
                        <input v-model="newMessage" @keyup.enter="sendMessage" type="text"
                            placeholder="Ask Salomé..." />
                        <button @click="sendMessage" class="send-button">Send</button>
                    </div>
                </div>
                <div v-else-if="error" class="content text-white">
                    <p class="bg-neutral-700 rounded margin-auto p-3">Salomé AI™ is currently out of office... </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db } from '@/main.js';
import { getAuth, signOut } from 'firebase/auth';
import { collection, query, orderBy, limit, getDocs, getCountFromServer } from 'firebase/firestore';
import LeftSidebar from './LeftSidebar.vue';
import Header from './Header.vue';
import ToastNotification from './utils/ToastNotification.vue'
import { EventBus } from '@/event-bus';

export default {
    name: 'Main',
    components: {
        LeftSidebar,
        Header,
        ToastNotification
    },
    data() {
        return {
            messages: [],
            newMessage: '',
            key: 'sk-proj-8LWsgEpvGdVWNaiqXHhqT3BlbkFJ6P3HOg8yROCNTfljYq9E',
            isLoading: false,
            error: null,
            data: null,
            global: null,
            branches: ['moorea', 'fiji'],
        };
    },
    async mounted() {
        await this.fetchUserName();
        if (this.userName) {
            this.sendWelcomeMessage();
        }
        await this.getData();
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        async fetchUserName() {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                this.userName = user.displayName ? user.displayName.split(' ')[0] : user.email.split('@')[0];
            }
        },
        sendWelcomeMessage() {
            const welcomeMessage = {
                text: `Hey ${this.userName}, whats up?`,
                type: 'system'
            };
            this.messages.push(welcomeMessage);
        },
        async getData() {
            const baseUrl = 'https://data.coralgardeners.org/data?org=coral-gardeners&pwd=showmethedata';
            this.error = null;
            const startTime = Date.now();
            EventBus.$emit('show-toast', { message: 'Calling Salomé...', type: '', loading: true });

            try {
                const globalPromise = fetch(baseUrl);
                const branchPromises = this.branches.map(branch =>
                    fetch(`${baseUrl}&branch=${branch}`)
                );
                const allPromises = [globalPromise, ...branchPromises];
                const timerPromise = new Promise(resolve => setTimeout(resolve, 2000));

                const responses = await Promise.all([...allPromises, timerPromise]);
                const [globalResponse, ...branchResponses] = responses.slice(0, -1); // Remove timer response

                if (!globalResponse.ok || branchResponses.some(response => !response.ok)) {
                    throw new Error(`HTTP error! status: ${globalResponse.status}`);
                }

                const parseResponse = async (response) => {
                    let text = await response.text();
                    text = text.replace(/\bNaN\b/g, 'null');
                    return this.parseJSON(text);
                };

                const globalData = await parseResponse(globalResponse);
                const branchData = await Promise.all(branchResponses.map(parseResponse));

                this.data = {
                    global: globalData,
                    ...Object.fromEntries(this.branches.map((branch, index) => [branch, branchData[index]]))
                };

                EventBus.$emit('show-toast', { message: 'She picked up!', type: 'success', loading: false });

            } catch (error) {
                console.error("Error fetching data:", error);
                this.data = null;
                this.error = error;
                EventBus.$emit('show-toast', { message: 'No one is home :(', type: 'error', loading: false });
            }
        },
        parseJSON(text) {
            return JSON.parse(text, (key, value) => {
                if (value === "NaN" || value === null || value === undefined || (typeof value === 'number' && isNaN(value))) {
                    return null;
                }
                return value;
            });
        },
        async handleLogout() {
            const auth = getAuth();
            try {
                await signOut(auth);
            } catch (error) {
                console.error("Logout failed: ", error.message);
            }
        },
        async sendMessage() {
            if (this.newMessage.trim() === '') return;

            const userMessage = {
                text: this.newMessage,
                type: 'user'
            };

            this.messages.push(userMessage);
            const question = this.newMessage;
            this.newMessage = '';
            this.isLoading = true;
            this.scrollToBottom();

            try {
                const systemMessage = await this.askOpenAI(question);
                this.messages.push({
                    text: systemMessage.replace(/\n/g, '<br>'),
                    type: 'system'
                });
            } catch (error) {
                console.error('Error fetching OpenAI response:', error);
                this.messages.push({
                    text: 'Error fetching response from Salomé AI™.',
                    type: 'system'
                });
            } finally {
                this.isLoading = false;
                this.scrollToBottom();
            }
        },
        async askOpenAI(question) {
            const dataStr = JSON.stringify(this.data);
            const currentDateTime = new Date().toLocaleString();

            // Prepare conversation history
            const conversationHistory = this.messages.map(msg => ({
                role: msg.type === 'user' ? 'user' : 'assistant',
                content: msg.text
            }));

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.key}`
                },
                body: JSON.stringify({
                    model: "gpt-4o-mini-2024-07-18",
                    messages: [
                        { role: "system", content: `You are a coral restoration expert and your goal is to answer questions about this data. The data is structured as a 'global' object with high level data and branch specific objects with more detailed data for ${this.branches}.` },
                        { role: "system", content: "Health refers to the health value and survival refers to fraction alive. The scale for health and bleaching is 1 to 6 where 1 is good and 6 is bad. Survival and fraction alive are on a scale of 0 to 1 (0 to 100%) where the higher the better. Genus_frac and species_frac refer to the diversity and distribution and NOT to the survival. If you can't answer the question with the current data please just say so." },
                        { role: "system", content: `The current date and time is: ${currentDateTime}. Your name is Salomé, you live in Mo'orea, you are blonde and 163 cm tall.` },
                        { role: "user", content: dataStr },
                        ...conversationHistory,
                        { role: "user", content: question }
                    ],
                    temperature: 0.2,
                    max_tokens: 1024,
                    frequency_penalty: 0.5,
                    presence_penalty: 0,
                })
            });

            const result = await response.json();
            const formattedResponse = result.choices[0].message.content.trim()
                .replace(/\n/g, '<br>')
                .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                .replace(/### (.*?)(<br>|$)/g, '<h3>$1</h3>')
                .replace(/## (.*?)(<br>|$)/g, '<h2>$1</h2>')
                .replace(/# (.*?)(<br>|$)/g, '<h1>$1</h1>')
                .replace(/# /g, '<br>');

            return formattedResponse;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const messagesContainer = this.$refs.messagesContainer;
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            });
        }
    },
};
</script>


<style scoped>
.main-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    background-color: black;
}

.main-content {
    display: flex;
    height: calc(100vh - 3rem);
    /* Adjust height to fit the screen minus header */
}

.content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-radius: 1rem;
    padding: 1rem;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}

.content::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.messages {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    padding-bottom: 5rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
}
.messages::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.message {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    max-width: 60%;
    word-wrap: break-word;
}

.message.user {
    align-self: flex-end;
    background-color: #27bdf4;
    color: white;
}

.message.system {
    align-self: flex-start;
    background-color: rgb(63,63,63);
    color: white;
}

.input-container {
    display: flex;
    padding: 0.5rem;
    background-color: #333;
    border-radius: 1rem;
    margin-top: 1rem;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 10;
}

.input-container input {
    flex-grow: 1;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    background-color: #222;
    color: white;
}

.send-button {
    background-color: #27bdf4;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

.loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    margin: 0 4px;
    animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

@media (min-width: 768px) {
    .input-container {
        max-width: calc(100% - 75px);
        left: 75px;
        right: 1rem;
    }
}

@media (max-width: 768px) {
    .messages {
        padding-bottom: 6rem;
        /* Increase padding on mobile to prevent content from being covered */
    }
}
</style>