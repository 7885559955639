<template>
    <div class="flex min-h-screen bg-neutral-100">

        <!-- Full Background Image -->
        <div class="absolute w-full h-full bg-cover z-0"
            :style="{ backgroundImage: 'url(' + require('@/assets/images/login_image.png') + ')' }">
        </div>

        <!-- Conditional Layout -->
        <div v-if="isMobile"
            class="w-full flex flex-col justify-center items-center px-4 bg-black bg-opacity-50 backdrop-filter backdrop-blur-md z-10">
            <!-- App name -->
            <div class="text-center flex items-center justify-center mb-2">
                <img src="../assets/images/cg.png" alt="Coral Gardeners" class="inline-block w-8 h-8 mr-2">
                <h2 class="text-white text-4xl">
                    <span>Reef</span><span class="font-bold">OS</span>
                </h2>
            </div>
            <p class="text-primary text-2xl text-center mb-8 uppercase font-bold">
                Reef Intelligence
            </p>

            <!-- Login Form -->
            <div class="flex justify-center items-center w-full">
                <div class="max-w-md">
                    <button class="w-full px-4 py-2 flex items-center justify-center" @click="loginWithGoogle">
                        <img src="../assets/images/web_dark_rd_SI.svg" alt="Sign in with Google" class="w-auto">
                    </button>
                </div>
            </div>
        </div>

        <div v-else
            class="w-1/2 flex flex-col justify-center px-16 bg-black bg-opacity-50 backdrop-filter backdrop-blur-md z-10">
            <!-- App name -->
            <div class="text-center flex items-center justify-center mb-2">
                <img src="../assets/images/cg.png" alt="Coral Gardeners" class="inline-block w-8 h-8 mr-2">
                <h2 class="text-white text-4xl">
                    <span>Reef</span><span class="font-bold">OS</span>
                </h2>
            </div>
            <p class="text-primary text-2xl text-center mb-8 uppercase font-bold">
                Reef Intelligence
            </p>

            <!-- Login Form -->
            <div class="flex justify-center items-center w-full">
                <div class="max-w-md">
                    <button class="w-full px-4 py-2 flex items-center justify-center" @click="loginWithGoogle">
                        <img src="../assets/images/web_dark_rd_SI.svg" alt="Sign in with Google" class="w-auto">
                    </button>
                </div>
            </div>
        </div>

        <div v-if="!isMobile" class="w-1/2">
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

export default {
    data() {
        return {
            errorMessage: '',
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        async loginWithGoogle() {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            try {
                const result = await signInWithPopup(auth, provider);
                const user = result.user;

                // Check if the email domain is coralgardeners.org
                if (user.email && user.email.endsWith('@coralgardeners.org')) {
                    console.log("Login successful:", user.email);
                } else {
                    // If the domain is not coralgardeners.org, sign out the user
                    await signOut(auth);
                    this.errorMessage = 'Login is restricted to Coral Gardeners organization members only.';
                    throw new Error(this.errorMessage);
                }
            } catch (error) {
                this.errorMessage = error.message;
                console.error("Login failed:", this.errorMessage);
            }
        },
    }
};
</script>

<style scoped>
body,
button,
input,
select,
textarea,
.app {
    font-family: 'Lato', sans-serif;
}
</style>
