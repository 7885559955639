import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/styles/index.css'

import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDnixHD6VRBAEu2M089rL0auDSsXxseg9k",
  authDomain: "reef-intelligence.firebaseapp.com",
  projectId: "reef-intelligence",
  storageBucket: "reef-intelligence.appspot.com",
  messagingSenderId: "1011309650522",
  appId: "1:1011309650522:web:1c0bb492c66d60d2b52e63",
  measurementId: "G-ZH04YPGP0Z"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app')
